import { getLastRefreshTime, setLastRefreshTime } from "utils/localStorage";

export const SET_PAGE_TITLE = "@@page/SET_PAGE_TITLE";

export function setPageTitle(pageTitle) {
  return async (dispatch) => {
    dispatch({
      type: SET_PAGE_TITLE,
      payload: pageTitle,
    });
  };
}

export const SET_WINDOW = "@@page/SET_WINDOW";

export function setWindow(window) {
  return async (dispatch) => {
    dispatch({
      type: SET_WINDOW,
      payload: window,
    });
  };
}

export const GET_WINDOW = "@@page/GET_WINDOW";

export function getWindow() {
  return async (dispatch) => {
    dispatch({
      type: GET_WINDOW,
    });
  };
}

export const SET_FROM_TO_DATE = "@@page/SET_FROM_TO_DATE";

export function setFromToDate(fromToDate) {
  return async (dispatch) => {
    dispatch({
      type: SET_FROM_TO_DATE,
      payload: fromToDate,
    });
  };
}

export const GET_FROM_TO_DATE = "@@page/GET_FROM_TO_DATE";

export function getFromToDate() {
  return async (dispatch) => {
    dispatch({
      type: GET_FROM_TO_DATE,
    });
  };
}

export const RESET_DATES = "@@page/RESET_DATES";

export function checkPageSettings() {
  return async (dispatch) => {
    let lastRefreshTime = getLastRefreshTime();
    if (lastRefreshTime) {
      const lastRefreshTimeSeconds =
        new Date().getTime() - parseInt(lastRefreshTime);
      const check = 15 * 60 * 1000;
      if (lastRefreshTimeSeconds > check) {
        dispatch(setWindow("day"));
        dispatch({
          type: RESET_DATES,
        });
        setLastRefreshTime(new Date().getTime());
      }
    } else {
      dispatch(setWindow("day"));
      dispatch(
        setFromToDate({
          start: null,
          end: null,
        })
      );
    }
  };
}
